
<template>
  <div id="app_preview_container">
    <!-- <p class="show_some_app_pictures pb-0 mb-0">
      <strong>
        {{ $t("lp_detail_some_app_pictures") }}
      </strong>
    </p> -->
    <p style="font-size: 0.85rem" class="pb-0 mb-2">
      {{ $t("lp_detail_some_app_pictures_click_to_next") }}
    </p>

    <table>
      <tr>
        <td>
          <!-- Images on devices -->
          <div class="container-image-large">
            <img
              draggable="false"
              style="position:absolute;top0;left:0;z-index:1"
              :src="require('@/assets/graphics/landing/devices_frames/pixel3xl.png')"
              class="mobile_frame d-flex justify-center"
              alt="mobile smarthpone frame"
              @click="next()"
            />

            <img
              class="inset-image-large"
              draggable="false"
              v-for="(image, index) in images_list"
              v-show="index == show_image_index"
              :key="image.url"
              :src="image.url"
              :alt="image.alt"
              id="inset_image"
              @click="next()"
            />

            <!-- <img
              class="inset-image-large"
              draggable="false"
              :src="url"
              :alt="alt"
              id="inset_image"
              @click="next()"
            /> -->
          </div>
          <div
            v-if="$vuetify.breakpoint.smAndDown"
            class="d-inline-block container_screenshot_title_description"
          >
            {{ show_image_index + 1 }} / {{ images_list.length }}
            <p class="pb-1 mb-0 screenshot_title" v-html="title"></p>
            <p class="screenshot_description" v-html="desc"></p>
          </div>
        </td>
        <td>
          <div
            v-if="!$vuetify.breakpoint.smAndDown"
            class="
              text-left
              ml-2
              d-inline-block
              container_screenshot_title_description
            "
          >
            {{ show_image_index + 1 }} / {{ images_list.length }}
            <p class="screenshot_title" v-html="title"></p>
            <p class="screenshot_description" v-html="desc"></p>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
// import { firebaseAnalytics } from "@/plugins/firebase";

export default {
  props: {
    images_list: {
      type: Array,
    },
    functionality: {
      type: String,
    },
  },
  components: {},
  data: () => ({
    image_count: 0,
    show_image_index: 0,
    url: null,
    alt: null,
    title: null,
    desc: null,
  }),
  methods: {
    scrollToPosition() {
      const el = document.getElementById("app_preview_container");
      let howToDescriptionHeight =
        document.getElementById("howToDescription").offsetHeight;
      let scroll_top;
      scroll_top = howToDescriptionHeight;
      window.scrollTo({ behavior: "smooth", top: scroll_top });
    },
    // prev() {
    //   if (this.show_image_index > 0) {
    //     this.show_image_index -= 1;
    //     this.url = this.images_list[this.show_image_index].url;
    //     this.alt = this.images_list[this.show_image_index].alt;
    //     this.title = this.images_list[this.show_image_index].title;
    //     this.desc = this.images_list[this.show_image_index].desc;
    //   }
    // },
    next() {
      this.scrollToPosition();

      // firebaseAnalytics.logEvent(
      //   "app_on_mobile_" + this.functionality + "_screenshot_next",
      //   {}
      // );

      if (this.show_image_index >= this.image_count - 1) {
        this.show_image_index = 0;
        this.url = this.images_list[0].url;
        this.alt = this.images_list[0].alt;
        this.title = this.images_list[0].title;
        this.desc = this.images_list[0].desc;
      } else {
        this.show_image_index += 1;
        this.url = this.images_list[this.show_image_index].url;
        this.alt = this.images_list[this.show_image_index].alt;
        this.title = this.images_list[this.show_image_index].title;
        this.desc = this.images_list[this.show_image_index].desc;
      }
    },
  },
  mounted() {
    this.image_count = this.images_list.length;
    this.url = this.images_list[0].url;
    this.alt = this.images_list[0].alt;
    this.title = this.images_list[0].title;
    this.desc = this.images_list[0].desc;
  },
  created() {},
  watch: {
    url() {
      document.getElementById("inset_image").classList.add("transitioning-src");
      setTimeout(() => {
        document
          .getElementById("inset_image")
          .classList.remove("transitioning-src");
      }, 1000);
    },
  },
};
</script>

<style scoped>
.container_screenshot_title_description {
  margin-top: 1rem;
  width: 200px;
  min-height: 200px;
}
.screenshot_title {
  font-size: 0.9rem;
  font-weight: bold;
}

.screenshot_description {
  font-size: 0.9rem;
}

.container-image-large {
  position: relative;
  width: 240px;
  height: 500px;
}

.container-desc-large {
  height: 500px;
  width: 240px;
  position: relative;
}

.mobile_frame {
  height: 500px;
}

.inset-image-large {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 224px;
  height: 457px !important;
  -o-object-fit: cover !important;
  -o-object-position: top;
  object-position: top;
  object-fit: fill !important;
  object-position: top;
}
</style>