<template>
  <div style="margin-bottom: 2rem">
    <hr />
    <p class="pb-0 mb-0 pt-2 pb-2" v-html="text"></p>
    <hr />
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
/* https://css-houdini.rocks/highlighter-marker-annotations/ */
sfhighlight {
  font-weight: bolder;
}
</style>